import React from "react"
import Pagination from "react-paginating"
import { Row } from "react-bootstrap"

const PaginationComponent = ({
  limit,
  pageCount,
  total,
  currentPage,
  onPageChange,
}): JSX.Element => {
  return (
    <div className="my-5">
      <Pagination
        total={total}
        limit={limit}
        pageCount={pageCount}
        currentPage={currentPage}
      >
        {({
          pages,
          currentPage,
          hasNextPage,
          hasPreviousPage,
          previousPage,
          nextPage,
          getPageItemProps,
        }) => (
            <Row className="d-flex flex-row w-lg-25 w-100 m-auto justify-content-center">
              <button
                className={`button-default button-pagination mr-1 ${
                  !hasPreviousPage ? "button-disabled" : ""
                  }`}
                {...getPageItemProps({
                  pageValue: previousPage,
                  onPageChange,
                })}
                disabled={!hasPreviousPage}
              >
                {"<"}
              </button>

              {pages.map((page, index) => {
                let activePage = null
                if (currentPage === page) {
                  activePage = { backgroundColor: "#00CB9B", color: "white" }
                }
                return (
                  <button
                    key={index}
                    className="button-default button-pagination  mr-1"
                    {...getPageItemProps({
                      pageValue: page,
                      style: activePage,
                      onPageChange: onPageChange,
                    })}
                  >
                    {page}
                  </button>
                )
              })}

              <button
                className={`button-default button-pagination ${
                  !hasNextPage ? "button-disabled" : ""
                  }`}
                {...getPageItemProps({
                  pageValue: nextPage,
                  onPageChange,
                })}
                disabled={!hasNextPage}
              >
                {">"}
              </button>
            </Row>
          )}
      </Pagination>
    </div>
  )
}

export default PaginationComponent
