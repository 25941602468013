import React from 'react'
import Skeleton from 'react-loading-skeleton';

const CardsSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
      <span style={{ width: '50%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Skeleton height={60} count={1} width={200} />
        <Skeleton height={60} count={1} width={200} />
        <Skeleton height={60} count={1} width={200} />
        <Skeleton height={60} count={1} width={200} />
      </span>
      <span style={{ width: '50%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', flexWrap: 'wrap' }}>
        <Skeleton height={60} count={1} width={200} />
        <Skeleton height={60} count={1} width={200} />
        <Skeleton height={60} count={1} width={200} />
        <Skeleton height={60} count={1} width={200} />
      </span>
    </div >
  )
}

export default CardsSkeleton;
