import { useIntl } from "gatsby-plugin-intl"
import React from 'react'
import { Row } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'
import { SORT_BY } from '../../constants/sortby'

const SortContainer = ({ handleClick, sortBy, sortByValue }): JSX.Element => {
  const intl = useIntl()

  const activeButton = (value) => sortBy === value ? "sort-active-button" : ''

  const activeArrowUp = (value) =>
    sortBy === value && sortByValue[value] ? "active-arrow" : null

  const activeArrowDowm = (value) =>
    sortBy === value && !sortByValue[value] ? "active-arrow" : ''

  const showTranslatedValue = (value) => {
    switch (value) {
      case SORT_BY.RANK:
        return intl.formatMessage({ id: 'agencies.rank' })
      case SORT_BY.LATEST_UPDATED:
        return intl.formatMessage({ id: 'agencies.latest_updated' })
      case SORT_BY.ASC:
      default:
        return value
    }
  }

  return (
    <Row className="justify-content-center mb-5">
      {Object.values(SORT_BY).map((value, index) => (
        <button
          key={index}
          onClick={() => handleClick(value)}
          className={`button-default sort-button d-flex justify-content-between align-items-center  ${activeButton(
            value
          )}`}
        >
          {showTranslatedValue(value)}
          <div>
            <FontAwesome
              name="long-arrow-up"
              className={`mr-lg-2 mr-1 ${activeArrowUp(value)}`}
            />
            <FontAwesome
              name="long-arrow-down"
              className={activeArrowDowm(value)}
            />
          </div>
        </button>
      ))}
    </Row>
  )
}

export default SortContainer
