import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MediaAgenciesList from "../components/media-agencies/media-agencies-list"
import { useIntl } from "gatsby-plugin-intl"
import Header from "../components/header"

const MediaAgenciesPage = (): JSX.Element => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'agencies.title' })}
        description={intl.formatMessage({ id: 'agencies.subtitle' })}
      />
      <Header
        searchForm={true}
        title={intl.formatMessage({ id: 'agencies.title' })}
        subtitle={intl.formatMessage({ id: 'agencies.subtitle' })}
        customStyle={{ padding: '6rem 0 2.9rem 0' }}
      />
      <MediaAgenciesList />
    </Layout>
  )
}

export default MediaAgenciesPage
