import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useIntl } from 'gatsby-plugin-intl'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import AgencyCard from '../../components/home/agency-card'
import { SORT_BY } from '../../constants/sortby'
import { sortMediaAgencies } from '../../utils/utils'
import CardsSkeleton from '../home/cards-skeleton'
import PaginationComponent from './pagination'
import SortContainer from './sort-container'

const MediaAgenciesList = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1)
  const [sortByValue, setSortByValue] = useState({
    [SORT_BY.RANK]: false,
  } as any)
  const [sortBy, setSortBy] = useState(SORT_BY.RANK)
  const { loading, error, data } = useQuery(APOLLO_QUERY)
  const [sortedAgencies, setSortedAgencies] = useState([])
  const intl = useIntl()

  const itemsPerPage = 15
  const indexOfLastAgency = currentPage * itemsPerPage
  const indexOfFirstAgency = indexOfLastAgency - itemsPerPage
  const currentAgencies = sortedAgencies.slice(
    indexOfFirstAgency,
    indexOfLastAgency
  )

  const onPageChange = (page, _) => {
    setCurrentPage(page)
  }

  const handleClick = (value: SORT_BY) => {
    setSortByValue({ [value]: !sortByValue[value] })
    setSortBy(value)
  }

  useEffect(() => {
    if (data && data.allMediaAgencies.length) {
      const sorted = sortMediaAgencies(
        [...data.allMediaAgencies],
        sortBy,
        sortByValue
      )
      setSortedAgencies(sorted)
    }
  }, [data, sortBy, sortByValue])

  const renderError = error ? <div>Eroare la încărcarea datelor</div> : <></>
  const renderSkeleton = loading ? <CardsSkeleton /> : <></>
  return (
    <>
      <Container className="py-5">
        <SortContainer
          handleClick={handleClick}
          sortBy={sortBy}
          sortByValue={sortByValue}
        />
        <Row noGutters>
          {renderError}
          {renderSkeleton}
          <Row>
            {currentAgencies.map((agency, index) => {
              return (
                <Col lg={4} md={6} xs={12} key={index}>
                  <AgencyCard agency={agency} />
                </Col>
              )
            })}
          </Row>
        </Row>
        <PaginationComponent
          limit={itemsPerPage}
          pageCount={5}
          total={sortedAgencies.length}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </Container>
    </>
  )
}

export default MediaAgenciesList

const APOLLO_QUERY = gql`
  {
    allMediaAgencies {
      id
      name
      photo {
        publicUrl
      }
      icon {
        publicUrl
      }
      createdAt
      updatedAt
      path
      verdicts(sortBy: verdictDate_DESC) {
        id
        createdAt
        verdictDate
        total
        credibilityLabel
      }
      url
    }
  }
`
